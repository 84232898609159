import { disableAreaTypes, enableAreaTypes } from '@/api/pmareatype';

const disableAreaType = {
	id: 'disableAreaType',
	selectionType: 'multiple',
	label: 'pmareatype.actions.disableAreaType',
	functionality: 'UPDATE_PMAREATYPE',
	checkAvailability: function (areatype) {
		return areatype && areatype.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmareatype.actions.disableAreaType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmareatypeid: registries[i].pmareatypeid
			});
		}

		const data = await disableAreaTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableAreaType = {
	id: 'enableAreaType',
	selectionType: 'multiple',
	label: 'pmareatype.actions.enableAreaType',
	functionality: 'UPDATE_PMAREATYPE',
	checkAvailability: function (areatype) {
		return areatype && areatype.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmareatype.actions.enableAreaType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmareatypeid: registries[i].pmareatypeid
			});
		}

		const data = await enableAreaTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableAreaType, enableAreaType]
};
